import { createContext, useState } from "react";

export const LoadingContext = createContext<{
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}>({
  isLoading: false,
  setLoading: () => {},
});

const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LoadingContext.Provider
      value={{
        setLoading: setIsLoading,
        isLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
