import { Dialog } from "@headlessui/react";
import React, { useContext } from "react";
import Modal from "./Modal";
import ig from "../assets/ig.svg";
import { UserContext } from "../contexts/UserContext";
import { updateUser } from "../api/user";
import { LoadingContext } from "../contexts/LoadingContext";
import Rive from "@rive-app/react-canvas";

const IGModal: React.FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const loader = useContext(LoadingContext);
  const userContext = useContext(UserContext);

  const onSubmit = async () => {
    loader.setLoading(true);

    window.open("https://www.tiktok.com/", "_blank", "noreferrer");
    await updateUser({ hasShared: true });
    await userContext?.updateUser();
    close();

    loader.setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} close={close}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
        <Dialog.Title as="h3" className="flex justify-center items-center">
          <div className="w-[222px] h-[222px]">
            <Rive src="https://public.rive.app/hosted/245541/76759/826io_xUuUCs3Ur9KBSgYQ.riv" />
          </div>
        </Dialog.Title>

        <div className="flex flex-col items-center mt-4">
          <p className="text-3xl font-bold text-[#655B58]">Oops!</p>
          <p className="text-xl text-[#655B58]">You've run out of rolls!</p>
          <p className="text-xl text-[#FE5D26] font-medium mt-2">
            Share to TikTok and get 1 more roll
          </p>

          <button
            className="relative mt-4 py-3 w-60 rounded-full font-semibold text-black border-black border-2"
            onClick={onSubmit}
          >
            Share
            <img
              className="absolute flex top-0 left-0 bottom-0 m-auto mx-4"
              src={ig}
              alt="ig icon"
            />
          </button>
          <button
            className="py-3 w-60 rounded-full font-semibold text-[#655B58]"
            onClick={close}
          >
            I'm done
          </button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default IGModal;
