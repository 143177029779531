import { onAuthStateChanged, getAuth } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../api/user";
import { RewardCategoryEnum } from "../configs";
import { LoadingContext } from "./LoadingContext";

export interface User {
  uid: string;
  username: string;
  email: string;
  roleChance: number;
  rewards: UserReward[];
  hasShared: boolean;
  appDownloaded: boolean;
  followerCount: number | null;
  profileUrl: string | null;
}

export interface UserReward {
  category: RewardCategoryEnum;
  code: string;
}

export const UserContext = createContext<
  | {
      user: User | undefined;
      showConnect: boolean;
      updateUser: () => Promise<void>;
      decrementRollCount: () => void;
      setShowConnect: (showConnect: boolean) => void;
    }
  | undefined
>(undefined);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const loader = useContext(LoadingContext);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [showConnect, setShowConnect] = useState(true);

  const updateUser = async () => {
    const currentUser = await getUser();
    setUser(currentUser);
  };

  const decrementRollCount = async () => {
    if (user) {
      setUser({
        ...user,
        roleChance: user.roleChance - 1,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      if (!user) {
        setUser(undefined);
        return;
      }

      localStorage.setItem("access_token", await user.getIdToken());

      const currentUser = await getUser();

      setUser(currentUser);
      setShowConnect(!currentUser);

      loader.setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [loader]);

  return (
    <UserContext.Provider
      value={{
        user,
        showConnect,
        updateUser,
        decrementRollCount,
        setShowConnect,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
