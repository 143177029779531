import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export default function RollCounter() {
  const userContext = useContext(UserContext);

  return (
    <div className="flex flex-col items-center gap-2 bg-[#655B58] p-4 rounded-lg">
      <span className="text-5xl font-bold text-[#FCFCFC]">
        {userContext?.user?.roleChance || 0}
      </span>
      <span className="text-1xl font-semibold text-[#FCFCFC]">roll left</span>
    </div>
  );
}
