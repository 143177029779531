import { RewardCategoryEnum } from "../configs";
import client, { ApiResponse } from "./client";

export interface Reward {
  type: RewardCategoryEnum;
  code?: string;
}

const getReward = async () => {
  const result = await client.get<ApiResponse<Reward | null>>("/reward");

  return result.data.data;
};

export { getReward };
