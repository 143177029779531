import "./App.css";
import Connect from "./components/Connect";
import Game from "./components/Game";
import NavBar from "./components/NavBar";
import { initializeApp } from "firebase/app";
import Loader from "./components/Loader";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJ_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

function App() {
  return (
    <main className="flex flex-col items-center">
      <Connect />
      <div className="background relative min-h-screen max-w-[800px]">
        <div className="canvas min-h-screen max-w-[800px]">
          <div className="relative p-4">
            <NavBar />
            <Game />
          </div>

          <div className="absolute bottom-0 flex flex-col items-center w-[100%] p-4">
            <div className="p-2 w-[150px] bg-black/50 rounded-xl cursor-pointer">
              <p
                className="text-center text-white text-xs"
                onClick={() =>
                  window.open(
                    "https://www.quik.gg/terms-conditions",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Terms & Conditions
              </p>
            </div>
          </div>
        </div>
      </div>

      <Loader />
    </main>
  );
}

export default App;
