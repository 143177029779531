import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ConfigContext } from "../contexts/ConfigContext";

export default function CountdownTimer() {
  const config = useContext(ConfigContext);

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);

  const calcDuration = useCallback(() => {
    const duration = moment.duration(moment(config.startTime).diff(moment()));
    setSeconds(duration.seconds());
    setMinutes(duration.minutes());
    setHours(duration.hours());
    setDays(duration.days());
  }, [config]);

  useEffect(() => {
    calcDuration();

    const timer = setInterval(() => calcDuration(), 1000);

    return () => clearInterval(timer);
  }, [calcDuration]);

  if (config.isCampaignActive || config.isCampaignEnded) {
    return <></>;
  }

  return (
    <div className="flex flex-col justify-center items-center bg-white rounded-xl w-80 my-4 gap-2 py-4">
      <span className="text-xs font-bold text-[#655B58]">Starting in</span>
      <div className="text-4xl font-bold text-[#655B58]">
        {days.toString().padStart(2, "0")} : {hours.toString().padStart(2, "0")}{" "}
        : {minutes.toString().padStart(2, "0")} :{" "}
        {seconds.toString().padStart(2, "0")}
      </div>
    </div>
  );
}
