import { Dialog } from "@headlessui/react";
import React, { useContext, useState } from "react";
import Modal from "./Modal";
import { UserContext } from "../contexts/UserContext";
import { updateUser } from "../api/user";
import { LoadingContext } from "../contexts/LoadingContext";
import Rive from "@rive-app/react-canvas";

const EmailModal: React.FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const userContext = useContext(UserContext);
  const loader = useContext(LoadingContext);
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    loader.setLoading(true);

    await updateUser({ email });
    await userContext?.updateUser();
    close();

    loader.setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} close={close}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
        <Dialog.Title as="h3" className="flex justify-center items-center">
          <div className="w-[222px] h-[222px]">
            <Rive src="https://public.rive.app/hosted/245541/76759/826io_xUuUCs3Ur9KBSgYQ.riv" />
          </div>
        </Dialog.Title>

        <div className="flex flex-col items-center mt-4">
          <p className="text-3xl font-bold text-[#655B58]">Oops!</p>
          <p className="text-xl text-[#655B58]">You've run out of rolls!</p>
          <p className="text-xl text-[#FE5D26] font-medium mt-2">
            Fill up your email to get 1 more roll
          </p>
          <input
            className="border-width-2 border-gray-300 border-solid border rounded-lg p-2 mt-4 w-60 placeholder:text-center"
            placeholder="your@email.com"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <button
            className="mt-3 py-3 w-60 bg-[#FE5D26] rounded-full font-semibold text-[#FFFFFF]"
            onClick={onSubmit}
          >
            Submit
          </button>
          <button
            className="py-3 w-60 rounded-full font-semibold text-[#655B58]"
            onClick={close}
          >
            I'm done
          </button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default EmailModal;
