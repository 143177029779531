import { AppConfig } from "../contexts/ConfigContext";
import client, { ApiResponse } from "./client";

const getConfig = async () => {
  const result = await client.get<ApiResponse<AppConfig>>("/config");

  return result.data.data;
};

export { getConfig };
