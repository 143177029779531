import { Dialog } from "@headlessui/react";
import React from "react";
import Modal from "./Modal";
import tiktok from "../assets/tiktok.svg";

const ConnectModal: React.FC<{
  isOpen: boolean;
  login: () => void;
  close: () => void;
}> = ({ isOpen, close, login }) => {
  return (
    <Modal isOpen={isOpen} close={close}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
        <Dialog.Title as="h3" className="flex justify-center items-center">
          <p className="text-3xl font-bold text-[#655B58]">Connect</p>
        </Dialog.Title>

        <div className="flex flex-col items-center mt-4">
          <p className="text-lg font-semibold text-[#655B58]">
            Prove that you have a TikTok account to proceed
          </p>

          <button
            className="relative mt-4 py-3 w-60 rounded-full font-semibold border-black border-2"
            onClick={login}
          >
            Connect
            <img
              className="absolute flex top-0 left-0 bottom-0 m-auto mx-3"
              style={{
                fill: "#FFFFFF",
              }}
              src={tiktok}
              width="40"
              alt="ig icon"
            />
          </button>
          <p className="mt-4 text-sm text-[#655B58]">
            TikTok details will not be stored and no account will be created
          </p>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default ConnectModal;
