import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getConfig } from "../api/config";
import { LoadingContext } from "./LoadingContext";

export interface AppConfig {
  startTime: Date;
  endTime?: Date;
  isCampaignActive: boolean;
  isCampaignEnded: boolean;
}

export const ConfigContext = createContext<AppConfig>({
  startTime: new Date(),
  isCampaignActive: false,
  isCampaignEnded: false,
});

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const loader = useContext(LoadingContext);
  const [config, setConfig] = useState<AppConfig>({
    startTime: new Date(),
    isCampaignActive: false,
    isCampaignEnded: false,
  });

  const loadConfig = useCallback(async () => {
    setConfig(await getConfig());
    loader.setLoading(false);
  }, [setConfig, loader]);

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigProvider;
