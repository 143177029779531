import { User } from "../contexts/UserContext";
import client, { ApiResponse } from "./client";

const getLoginUrl = async () => {
  const result = await client.get<
    ApiResponse<{
      loginUrl: string;
    }>
  >("/login");

  return result.data.data?.loginUrl;
};

const getUser = async () => {
  const result = await client.get<ApiResponse<User>>("/user");

  return result.data.data;
};

const updateUser = async (data: {
  email?: string;
  hasShared?: boolean;
  appDownloaded?: boolean;
}) => {
  await client.patch<ApiResponse<User>>("/user", data);
  return true;
};

export { getLoginUrl, getUser, updateUser };
