import axios from "axios";

export interface ApiResponse<T> {
  data: T;
  error: string | undefined;
  success: boolean;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default client;
