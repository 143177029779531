import React, { useContext } from "react";
import { ThreeDots } from "react-loader-spinner";
import { LoadingContext } from "../contexts/LoadingContext";

export default function Loader() {
  const loader = useContext(LoadingContext);

  if (!loader.isLoading) {
    return null;
  }

  return (
    <div className="absolute flex justify-center items-center w-full h-full top-0 left-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <ThreeDots
        height={100}
        width={100}
        radius={5}
        color="#FE5D26"
        visible={true}
      />
    </div>
  );
}
