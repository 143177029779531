import grab5 from "./assets/grab-5.png";
import grab15 from "./assets/grab-15.png";
import grab30 from "./assets/grab-30.png";
import grab50 from "./assets/grab-50.png";
import tng10 from "./assets/tng-10.png";
import tgv10 from "./assets/tgv-10.png";
import airpods from "./assets/airpods.png";
import reroll from "./assets/reroll.png";
import quikReward from "./assets/quik-reward.png";
import Rive from "@rive-app/react-canvas";

export enum RewardCategoryEnum {
  NONE = "NONE",
  GRAB_5 = "GRAB_5",
  GRAB_15 = "GRAB_15",
  GRAB_30 = "GRAB_30",
  GRAB_50 = "GRAB_50",
  TNG_10 = "TNG_10",
  TGV_10 = "TGV_10",
  REROLL = "REROLL",
  QUIK = "QUIK",
  AIRPODS = "AIRPODS",
}

export const RewardDescription = {
  [RewardCategoryEnum.NONE]: "No reward",
  [RewardCategoryEnum.GRAB_5]: "RM5 Grab Voucher",
  [RewardCategoryEnum.GRAB_15]: "RM15 Grab Voucher",
  [RewardCategoryEnum.GRAB_30]: "RM30 Grab Voucher",
  [RewardCategoryEnum.GRAB_50]: "RM50 Grab Voucher",
  [RewardCategoryEnum.TNG_10]: "RM10 Touch 'n Go eWallet Reload",
  [RewardCategoryEnum.TGV_10]: "RM10 TGV eVoucher",
  [RewardCategoryEnum.REROLL]:
    "You got yourself one more roll chance! Spin the Wheel again!",
  [RewardCategoryEnum.QUIK]: "QUIK with 0% fee",
  [RewardCategoryEnum.AIRPODS]: "AirPods (3rd Gen)",
};

export interface RewardType {
  category: RewardCategoryEnum;
  img: JSX.Element;
}

export const GRAB_REWARDS: RewardType[] = [
  {
    category: RewardCategoryEnum.GRAB_5,
    img: <img src={grab5} alt="reward img" />,
  },
  {
    category: RewardCategoryEnum.GRAB_15,
    img: <img src={grab15} alt="reward img" />,
  },
  {
    category: RewardCategoryEnum.GRAB_30,
    img: <img src={grab30} alt="reward img" />,
  },
  {
    category: RewardCategoryEnum.GRAB_50,
    img: <img src={grab50} alt="reward img" />,
  },
];

export const TNG_REWARDS: RewardType[] = [
  {
    category: RewardCategoryEnum.TNG_10,
    img: <img src={tng10} alt="reward img" />,
  },
];

export const TGV_REWARDS: RewardType[] = [
  {
    category: RewardCategoryEnum.TGV_10,
    img: <img src={tgv10} alt="reward img" />,
  },
];

export const QUIK_REWARD: RewardType[] = [
  {
    category: RewardCategoryEnum.QUIK,
    img: <img src={quikReward} alt="reward img" />,
  },
];

export const NO_REWARDS: RewardType[] = [
  {
    category: RewardCategoryEnum.NONE,
    img: (
      <Rive src="https://public.rive.app/hosted/245541/76758/k23BmPB-kkudXN0h_TqqSQ.riv" />
    ),
  },
];

export const REROLL_REWARDS: RewardType[] = [
  {
    category: RewardCategoryEnum.REROLL,
    img: <img src={reroll} alt="reward img" />,
  },
];

export const AIRPODS_REWARD: RewardType[] = [
  {
    category: RewardCategoryEnum.AIRPODS,
    img: <img src={airpods} alt="reward img" />,
  },
];

export const rewardsQuadrants = [
  { quadrant: 1, type: GRAB_REWARDS },
  { quadrant: 2, type: QUIK_REWARD },
  { quadrant: 3, type: TNG_REWARDS },
  { quadrant: 4, type: GRAB_REWARDS },
  { quadrant: 5, type: NO_REWARDS },
  { quadrant: 6, type: TGV_REWARDS },
  { quadrant: 7, type: TNG_REWARDS },
  { quadrant: 8, type: REROLL_REWARDS },
  { quadrant: 9, type: AIRPODS_REWARD },
  { quadrant: 10, type: NO_REWARDS },
];
