import { Dialog } from "@headlessui/react";
import React, { useState } from "react";
import q from "../assets/q.svg";
import Modal from "./Modal";
import igBackground from "../assets/ig-bg.png";
import spinWheelBackground from "../assets/spin-wheel-bg.png";
import prizeBackground from "../assets/prize-bg.png";
import shareBackground from "../assets/share-bg.png";

export default function Info() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col gap-3">
      <div
        className="flex justify-center items-center w-10 h-10 bg-white rounded-full cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <span className="text-3xl font-bold text-[#655B58]">?</span>
      </div>
      <div
        className="flex justify-center items-center w-10 h-10 bg-white rounded-full cursor-pointer"
        onClick={() =>
          window.open("https://link.quik.gg/", "_blank", "noreferrer")
        }
      >
        <img src={q} alt="q" />
      </div>

      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <Dialog.Panel className="w-full max-w-md  transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all">
          <Dialog.Title as="h3" className="text-center text-[#FE5D26] p-8">
            <p className="text-lg font-semibold">Spin the Wheel</p>
            <p className="text-2xl font-bold">For Cash Prizes!</p>
          </Dialog.Title>

          <div className="mt-4 text-[#655B58]">
            <p className="my-4">Here's how it works</p>

            <div className="text-left">
              <div className="flex items-center">
                <img
                  className="object-cover w-[86px]"
                  src={igBackground}
                  alt="ig background"
                />

                <div className="px-6">
                  <p className="my-4">
                    1. Connect your Tiktok profile and get awarded spins based
                    on your number of followers.
                  </p>
                  <p className="my-4">
                    (Rest assured, connecting your Tiktok account is secure and
                    hassle-free. We respect your data and privacy and only
                    require it for necessary validation purposes.)
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <img
                  className="object-cover w-[86px]"
                  src={spinWheelBackground}
                  alt="spin wheel background"
                />

                <div className="px-6">
                  <p className="my-1">
                    2. Press the 'Spin' button and watch the wheel go!
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <img
                  className="object-cover w-[86px]"
                  src={prizeBackground}
                  alt="spin wheel background"
                />

                <div className="px-6">
                  <p className="my-1">
                    3. When it lands on a section, a pop-up will appear with
                    instructions on how to claim your prize. Follow them closely
                    to make sure you don't miss out!
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <img
                  className="object-cover w-[86px]"
                  src={shareBackground}
                  alt="spin wheel background"
                />

                <div className="px-6">
                  <p className="my-1">
                    4. Excited about your results? Share them on your Tiktok
                    story and make sure to tag @usequik. We'll give you an
                    additional spin as a thank-you!
                  </p>
                </div>
              </div>

              <div className="p-8">
                <p className="mb-4">
                  Get ready to spin, win, and enjoy the rewards.
                </p>
                <p>Good luck!</p>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Modal>
    </div>
  );
}
