import { Menu, Transition } from "@headlessui/react";
import { getAuth, signOut } from "firebase/auth";
import { Fragment, useContext } from "react";
import quikLogo from "../assets/quik.svg";
import { UserContext } from "../contexts/UserContext";

export default function NavBar() {
  const userContext = useContext(UserContext);

  return (
    <div>
      <div className="flex justify-between items-center my-4">
        <img src={quikLogo} alt="QUIK Logo" width={150} height={120} />

        {userContext?.user && (
          <div className="flex items-center gap-2">
            <div className="flex flex-col text-right">
              <span
                style={{
                  width: 120,
                }}
                className="text-sm font-bold overflow-hidden, text-ellipsis whitespace-nowrap"
              >
                {userContext.user?.username}
              </span>
              <span className="text-xs">Connected</span>
            </div>

            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button>
                <img
                  src={
                    userContext.user.profileUrl ||
                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt="avatar"
                  width={40}
                  height={40}
                  className="rounded-full"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <button
                        className="group flex w-full items-center rounded-md px-2 py-2 text-sm"
                        onClick={() => {
                          userContext.setShowConnect(true);
                          signOut(getAuth());
                        }}
                      >
                        Sign Out
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
}
