import { useContext, useState } from "react";
import trophy from "../assets/trophy.svg";
import { Dialog } from "@headlessui/react";
import Modal from "./Modal";
import { UserContext } from "../contexts/UserContext";
import { RewardDescription } from "../configs";

export default function History() {
  const userContext = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col gap-3">
      <div
        className="flex justify-center items-center w-10 h-10 bg-white rounded-full cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <img src={trophy} alt="q" />
      </div>

      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
          <Dialog.Title
            as="h3"
            className="text-xl font-black text-center text-[#655B58]"
          >
            Win History
          </Dialog.Title>

          <div className="mt-2">
            {!(userContext?.user?.rewards || []).length && (
              <p className="text-sm text-gray-500">Nothing won yet!</p>
            )}

            {userContext?.user?.rewards.map((reward) => (
              <div className="w-full flex justify-between py-2 px-4 border-b-2 text-left">
                <p className="text-sm text-gray-500">
                  {RewardDescription[reward.category]}
                </p>
                <p className="text-sm text-gray-500">{reward.code}</p>
              </div>
            ))}
          </div>
        </Dialog.Panel>
      </Modal>
    </div>
  );
}
