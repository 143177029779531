import { useContext } from "react";
import { ConfigContext } from "../contexts/ConfigContext";

export default function CampaignComplete() {
  const config = useContext(ConfigContext);

  if (!config.isCampaignEnded) {
    return <></>;
  }

  return (
    <div className="flex flex-col justify-center items-center bg-white rounded-xl w-80 my-4 py-4">
      <span className="text-2xl font-extrabold text-[#FE5D26] my-2">
        Campaign Completed
      </span>
      <span className="text-md text-[#655B58]">Stay tunned for</span>
      <span className="text-md font-bold text-[#655B58]">more giveaways!</span>
    </div>
  );
}
