import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserProvider from "./contexts/UserContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./Auth";
import LoadingProvider from "./contexts/LoadingContext";
import ConfigProvider from "./contexts/ConfigContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
]);

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <ConfigProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </ConfigProvider>
    </LoadingProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
