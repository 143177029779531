import { getAuth, signInWithCustomToken } from "firebase/auth";
import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingContext } from "./contexts/LoadingContext";

export default function Auth() {
  const loader = useContext(LoadingContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const login = useCallback(
    async (customToken: string) => {
      loader.setLoading(true);

      await signInWithCustomToken(getAuth(), customToken);

      navigate("/");
    },
    [navigate, loader]
  );

  useEffect(() => {
    const customToken = searchParams.get("customToken");
    if (customToken) {
      login(customToken);
    }

    navigate("/");
  }, [searchParams, login, navigate]);

  return <></>;
}
