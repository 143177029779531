import { useContext, useState } from "react";
import { getLoginUrl } from "../api/user";
import { UserContext } from "../contexts/UserContext";
import CampaignComplete from "./CampaignComplete";
import ConnectModal from "./ConnectModal";
import CountdownTimer from "./CountdownTimer";

export default function Connect() {
  const userContext = useContext(UserContext);
  const [showConnectModal, setShowConnectModal] = useState(false);

  const onLogin = async () => {
    window.location.href = await getLoginUrl();
  };

  if (!userContext?.showConnect) {
    return null;
  }

  return (
    <div className="absolute flex justify-center items-center w-full h-full top-0 left-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-20">
      <div className="flex flex-col items-center gap-3">
        <CampaignComplete />
        <CountdownTimer />
        <button
          className="w-80 h-12 text-lg font-bold text-[#FCFCFC] bg-[#FE5D26] rounded-full cursor-pointer"
          onClick={() => setShowConnectModal(true)}
        >
          Play Now
        </button>
        <span
          className="text-md text-[#FCFCFC] font-semibold cursor-pointer"
          onClick={() => userContext?.setShowConnect(false)}
        >
          Play Later
        </span>
      </div>

      <ConnectModal
        isOpen={showConnectModal}
        login={onLogin}
        close={() => setShowConnectModal(false)}
      />
    </div>
  );
}
