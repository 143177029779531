import { useCallback, useContext, useEffect, useRef, useState } from "react";
import spinner from "../assets/spinner.png";
import spinButton from "../assets/spin_button.png";
import { motion, cubicBezier, useAnimationControls } from "framer-motion";
import { getReward, Reward } from "../api/reward";
import { UserContext } from "../contexts/UserContext";
import { ConfigContext } from "../contexts/ConfigContext";
import RollCounter from "./RollCounter";
import Info from "./Info";
import History from "./History";
import noReward from "../assets/no-reward.png";
import copy from "../assets/copy.svg";
import tickSound from "../assets/sound.wav";
import Modal from "./Modal";
import { Dialog } from "@headlessui/react";
import EmailModal from "./EmailModal";
import IGModal from "./IGModal";
import DownloadQUIKModal from "./DownloadQUIKModal";
import {
  RewardCategoryEnum,
  RewardDescription,
  rewardsQuadrants,
} from "../configs";
import { Howl } from "howler";

export default function Game() {
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const [reward, setReward] = useState<{
    type: RewardCategoryEnum;
    code?: string;
    img: JSX.Element;
  } | null>(null);
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [canSpin, setCanSpin] = useState(true);
  const [spinTitle, setSpinTitle] = useState("Connect");
  const [spinAction, setSpinAction] = useState(() => () => {});

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isIGModalOpen, setIsIGModalOpen] = useState(false);
  const [isDLQuikModalOpen, setIsDLQuikModalOpen] = useState(false);

  const controls = useAnimationControls();
  const baseRotation = 3600;

  let lastTick = useRef(0);

  useEffect(() => {
    controls.set({
      scale: 0.8,
    });
  }, [controls]);

  const spinWheel = useCallback(async () => {
    try {
      setCanSpin(false);

      lastTick.current = 0;
      controls.set({ rotate: 0 });
      controls.start({
        rotate: baseRotation,
        transition: {
          duration: 1,
          ease: "linear",
          repeat: Infinity,
        },
      });

      let rewardResult: Reward | null = null;

      if (config.isCampaignActive) {
        rewardResult = await getReward();
        user?.decrementRollCount();
      } else {
        rewardResult = {
          type: Object.values(RewardCategoryEnum)[
            Math.floor(Math.random() * Object.values(RewardCategoryEnum).length)
          ],
        };
      }

      const selectedRewards = rewardsQuadrants.filter((a) =>
        a.type
          .map((b) => b.category)
          .includes(rewardResult?.type || RewardCategoryEnum.NONE)
      );

      const finalRewards =
        selectedRewards[Math.floor(Math.random() * selectedRewards.length)];

      const quadrant = finalRewards.quadrant;

      setReward({
        type: rewardResult?.type || RewardCategoryEnum.NONE,
        code: rewardResult?.code,
        img: finalRewards.type.find(
          (a) => a.category === (rewardResult?.type || RewardCategoryEnum.NONE)
        )?.img || <img src={noReward} alt="reward img" />,
      });

      const seed = Math.random() * (0.9 - 0.1) + 0.1;
      const offset = 36 * seed + (quadrant - 1) * 36;

      lastTick.current = 0;
      controls.set({ rotate: 0 });
      await controls.start({
        rotate: baseRotation + 18 - offset,
        transition: {
          ease: cubicBezier(0, 0.99, 0.44, 0.99),
          duration: 10,
        },
      });

      if (config.isCampaignActive && user) {
        user?.updateUser();
      }

      setTimeout(() => {
        setShowReward(false);
        setIsRewardModalOpen(true);
      }, 500);
    } catch (e) {
      alert("Out of roll chance!");
    } finally {
      setCanSpin(true);
    }
  }, [controls, user, config]);

  useEffect(() => {
    if (!config.isCampaignActive) {
      setSpinTitle("Demo Spin");
      setSpinAction(() => spinWheel);

      return;
    }

    if (!user?.user) {
      setSpinTitle("Connect");
      setSpinAction(() => () => user?.setShowConnect(true));
      return;
    }

    if (user?.user?.roleChance === 0) {
      if (!user.user.hasShared) {
        setSpinTitle("Get more Rolls");
        setSpinAction(() => () => setIsIGModalOpen(true));

        return;
      }

      if ((user?.user?.followerCount || 0) >= 1000) {
        if (!user.user.email) {
          setSpinTitle("Get more Rolls");
          setSpinAction(() => () => setIsEmailModalOpen(true));

          return;
        }

        if (!user.user.appDownloaded) {
          setSpinTitle("Get more Rolls");
          setSpinAction(() => () => setIsDLQuikModalOpen(true));

          return;
        }
      }

      setSpinTitle("No more Roll");
      setSpinAction(() => () => {});

      return;
    }

    setSpinTitle("Spin");
    setSpinAction(() => spinWheel);
  }, [user, spinWheel, config]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const regex = /rotate\((-?\d+(\.\d+)?)deg\)/;

    const obs = new MutationObserver((val) => {
      const matches = (val[0].target as any).style.transform.match(regex);

      if (matches && matches[1]) {
        const deg = parseFloat(matches[1]);

        if (deg % 36 > 0 && deg - lastTick.current > 36) {
          lastTick.current = deg;
          new Howl({
            src: [tickSound],
          }).play();
        }
      }
    });

    if (ref.current) {
      obs.observe(ref.current, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    return () => {
      obs.disconnect();
    };
  }, [ref, lastTick]);

  return (
    <div className="w-full flex flex-col justify-center items-center overflow-hidden">
      <div className="w-full flex justify-between px-4 mt-4">
        <Info />
        <RollCounter />
        <History />
      </div>

      <div className="relative">
        <motion.div ref={ref} animate={controls}>
          <img src={spinner} alt="spinner" />
        </motion.div>

        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-[95%]">
          <img
            src={spinButton}
            alt="spin"
            style={{
              width: "55%",
            }}
          />
          <div className="flex justify-center items-center absolute top-[3%] left-0 w-full h-full select-none">
            <div
              style={{
                background:
                  spinTitle !== "Get more Rolls" ? "#FE5D26" : "#655B58",
              }}
              className=" flex justify-center items-center w-[30%] h-[30%] rounded-full p-4"
            >
              <span
                className="text-center cursor-pointer text-lg font-tanNimbus text-white "
                onClick={canSpin ? spinAction : () => {}}
              >
                {spinTitle}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isRewardModalOpen}
        close={() => setIsRewardModalOpen(false)}
      >
        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
          <Dialog.Title as="h3" className="flex justify-center items-center">
            <div className="w-[222px] h-[222px] rounded-lg">{reward?.img}</div>
          </Dialog.Title>

          <div className="flex flex-col mt-4 items-center">
            {reward?.type === RewardCategoryEnum.NONE && (
              <p className="text-3xl font-extrabold text-[#655B58]">Oops!</p>
            )}

            {reward?.type !== RewardCategoryEnum.NONE && (
              <p className="text-3xl font-extrabold text-[#FE5D26]">
                {" "}
                {reward?.type === RewardCategoryEnum.REROLL
                  ? "One more Chance!"
                  : "You won!"}
              </p>
            )}

            {reward?.type !== RewardCategoryEnum.NONE && (
              <p className="mt-2 font-semibold text-md">
                {RewardDescription[reward?.type || RewardCategoryEnum.NONE]}
              </p>
            )}

            <b className="text-md text-[#655B58]">
              {reward?.type === RewardCategoryEnum.NONE &&
                "Better luck next time"}
            </b>

            {reward?.type === RewardCategoryEnum.REROLL && (
              <button
                className="mt-8 py-3 w-60 bg-[#FE5D26] rounded-full font-semibold text-[#FFFFFF]"
                onClick={() => setIsRewardModalOpen(false)}
              >
                Okay
              </button>
            )}

            {reward?.type !== RewardCategoryEnum.REROLL &&
              (!showReward ? (
                <button
                  className="mt-8 py-3 w-60 bg-[#FE5D26] rounded-full font-semibold text-[#FFFFFF]"
                  onClick={() => {
                    if (reward?.type === RewardCategoryEnum.AIRPODS) {
                      if (config.isCampaignActive) {
                        window.open(
                          "https://wa.me/601159543661?text=I+won+AirPod3+from+QUIK+roll",
                          "_blank",
                          "noreferrer"
                        );
                      }
                      return;
                    }

                    reward?.type === RewardCategoryEnum.NONE
                      ? setIsRewardModalOpen(false)
                      : setShowReward(true);
                  }}
                >
                  {reward?.type === RewardCategoryEnum.NONE ? "Retry" : "Claim"}
                </button>
              ) : (
                <div
                  className="relative mt-8 py-3 w-60  rounded-full font-semibold text-[#B2AEAC] border-[#B2AEAC] border-2"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      reward?.code || "CODE-FOR-PRIZES"
                    )
                  }
                >
                  {reward?.code || "CODE-FOR-PRIZES"}
                  <span className="absolute w-full h-full flex justify-end items-center top-0 left-0 px-3">
                    <img src={copy} alt="copy" />
                  </span>
                </div>
              ))}
          </div>
        </Dialog.Panel>
      </Modal>

      <EmailModal
        isOpen={isEmailModalOpen}
        close={() => setIsEmailModalOpen(false)}
      />

      <IGModal isOpen={isIGModalOpen} close={() => setIsIGModalOpen(false)} />

      <DownloadQUIKModal
        isOpen={isDLQuikModalOpen}
        close={() => setIsDLQuikModalOpen(false)}
      />
    </div>
  );
}
